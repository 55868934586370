import React, { useEffect, useState } from "react";
import { FiPlus } from "react-icons/fi";
import { HiChevronDoubleDown, HiChevronDoubleUp } from "react-icons/hi";
import Item from "./Item";
import { imageDb } from "../../integrations/FirebaseConfig";
import { ref, uploadBytes } from "firebase/storage";
import { v4 } from "uuid";
import BackendService from "../../integrations/BackendService";
import { CurrencyInput, Currencies, Locales } from "input-currency-react";
import { BsTrash } from "react-icons/bs";
import Swal from "sweetalert2";
import CategoriaAdicional from "../Categoria Adicional/CategoriaAdicional";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { AiOutlineLoading } from "react-icons/ai";
import { BiSolidToggleLeft, BiSolidToggleRight } from "react-icons/bi";
import { Switch } from "@mui/material";

export default function Categoria({ categoria: categoriaInicial, onExcluir, listaAdicionais }) {
	const backendService = new BackendService();
	const [isExpanded, setIsExpanded] = useState(false);
	const [categoria, setCategoria] = useState(categoriaInicial)

	useEffect(() => {
		if (categoriaInicial) {
		  setCategoria(categoriaInicial);
		}
	  }, [categoriaInicial]);

	const { data: pratosDaCategoria, isLoading } = useQuery({
		queryKey: ['buscarPratosDaCategoria', categoria.id],
		queryFn: async () => {
			const response = await backendService.buscarPratosDaCategoria(categoria.id);
			return response
		},
		enabled: isExpanded
	});

	const [categoriasAdicionais, setCategoriasAdicionais] = useState([]);
	const adicionarCategoria = () => {
		setCategoriasAdicionais([...categoriasAdicionais, {}]);
	};

	const [categoriaState, setCategoriaState] = useState(categoria);

	const [modalAberto, setModalAberto] = useState(false);
	const [novoItem, setNovoItem] = useState({
		idCategoriaPrato: "",
		prato: {
			nome: "",
			descricao: "",
			preco: "",
			idImagem: "",
		},
		idAdicional: [],
	});

	const handleAbrirModal = () => {
		setModalAberto(true);
	};

	const handleFecharModal = () => {
		setModalAberto(false);
		setNovoItem({
			idCategoriaPrato: "",
			prato: {
				nome: "",
				descricao: "",
				preco: "",
				idImagem: "",
			},
		});
		setCategoriasAdicionais([])
	};

	const handleChangeNovoItem = (campo, valor) => {
		setNovoItem({
			...novoItem,
			prato: {
				...novoItem.prato,
				[campo]: valor,
			},
		});
	};

	const handleChangePreco = (campo, valor) => {
		valor = valor
			.replace(/\s/g, "")
			.replace("R$", "")
			.replace(".", "")
			.replace(",", ".");
		setNovoItem({
			...novoItem,
			prato: {
				...novoItem.prato,
				["preco"]: valor,
			},
		});
	};

	const handleSalvarNovoItem = async () => {
		let categoriasAdicionaisValidadas = categoriasAdicionais;

		if (categoriasAdicionais.length > 0) {
			categoriasAdicionaisValidadas = categoriasAdicionais.filter(categoria =>
				categoria.adicionais?.length > 0 &&
				categoria.nome &&
				categoria.quantidadeMaxima &&
				(categoria.quantidadeMinima || categoria.quantidadeMinima === 0)
			);

			// Se não houver categorias válidas, mostre uma mensagem e saia da função
			if (categoriasAdicionaisValidadas.length !== categoriasAdicionais.length) {
				Swal.fire({
					title: "Erro!",
					text: "Existe categoria de adicional incompleta.",
					icon: "info",
					customClass: {
						container: 'swal2-container', // Classe personalizada aplicada aqui
					},
				});
				return;
			}

			// Verificar se algum valor máximo é menor que o valor mínimo
			const categoriaInvalida = categoriasAdicionaisValidadas.find(categoria =>
				categoria.quantidadeMaxima < categoria.quantidadeMinima
			);

			if (categoriaInvalida) {
				Swal.fire({
					title: "Erro!",
					text: "Uma ou mais categorias têm valor máximo menor que o valor mínimo.",
					icon: "info",
					customClass: {
						container: 'swal2-container', // Classe personalizada aplicada aqui
					},
				});
				return;
			}
		}
		Swal.fire({
			title: "Salvando...",
			text: "Por favor, aguarde enquanto salvamos o item.",
			allowOutsideClick: false,
			customClass: {
				container: 'swal2-container', // Classe personalizada aplicada aqui
			},
			didOpen: () => {
				Swal.showLoading();
			},
		});
		try {
			const categoriaId = categoria.id;

			// Aqui você pode adicionar lógica para salvar o novo item

			const uuid = v4();
			const imgRef = ref(
				imageDb,
				`${localStorage.getItem('restaurante')}/imagemProdutos/${uuid}`
			);
			await uploadBytes(imgRef, novoItem.prato.foto);
			setNovoItem((prevState) => ({
				...prevState,
				idCategoriaPrato: categoriaId,
				prato: {
					...prevState.prato,
					idImagem: uuid, // atualiza o uuid
				},
			}));
			if (!novoItem.prato.preco) {
				novoItem.prato.preco = 0; // Se o preço for vazio, define como 0
			}
			const itemParaSalvar = {
				...novoItem,
				idCategoriaPrato: categoriaId,
				prato: {
					...novoItem.prato,
					idImagem: uuid,
				},
				categoriaAdicionalList: categoriasAdicionaisValidadas
			};
			adicionarPrato(itemParaSalvar);

			// // Depois de salvar, você pode fechar o modal
			handleFecharModal();
			setNovoItem({
				idCategoriaPrato: "",
				prato: {
					nome: "",
					descricao: "",
					preco: "",
					idImagem: "",
				},
			});

			Swal.fire({
				title: "Item Adicionado!",
				text: "O item foi Adicionado com sucesso..",
				icon: "success",
				customClass: {
					container: 'swal2-container', // Classe personalizada aplicada aqui
				},
			}).then((result) => {
				if (result.isConfirmed) {
					window.location.reload();
				}
			});
		} catch (error) {
			Swal.fire({
				title: "Erro!",
				text: "Houve um erro ao salvar o item. Tente novamente.",
				icon: "error",
				customClass: {
					container: 'swal2-container', // Classe personalizada aplicada aqui
				},
			});
			console.error("Erro ao salvar o item:", error);
		}
	};

	const adicionarPrato = async (prato) => {
		try {
			await backendService.criarPrato(prato);
		} catch (error) {
			console.error(error.message);
		}
	};

	const handleExcluirPrato = (pratoId) => {
		const novosPratos = categoriaState.pratos.filter(
			(prato) => prato.id !== pratoId
		);
		setCategoriaState({
			...categoriaState,
			pratos: novosPratos,
		});
	};

	const [modalAbertoExcluirCategoria, setModalAbertoExcluirCategoria] =
		useState(false);
	const abrirModalExcluirCategoria = () =>
		setModalAbertoExcluirCategoria(true);
	const fecharModalExcluirCategoria = () =>
		setModalAbertoExcluirCategoria(false);

	const handleExcluirCategoria = async () => {
		try {
			await backendService.deletarCategoria(categoria.id);
			setModalAbertoExcluirCategoria(false);
			onExcluir(categoria.id);
			window.location.reload();
		} catch (error) {
			console.error(error.message);
		}
	};



	const toggleExpand = () => {
		setIsExpanded(!isExpanded);
	};

	const handleToggleCategoria = async (categoria) => {
		if (categoria.ativo) {
			Swal.fire({
				title: "Inativando...",
				text: "Estamos inativando sua categoria.",
				allowOutsideClick: false,
				didOpen: () => {
					Swal.showLoading();
				},
			});

		} else {
			Swal.fire({
				title: "Ativando...",
				text: "Estamos ativando sua categoria.",
				allowOutsideClick: false,
				didOpen: () => {
					Swal.showLoading();
				},
			});
		}
		await backendService.ativarDesativarCategoriaPrato(
			categoria.id,
			categoria.ativo ? "desativar" : "ativar"
		);
		setCategoria((prev) => ({
			...prev,
			ativo: !prev.ativo,
		  }));
		Swal.close();
	}

	const isPratosEmpty =
		!categoriaState.pratos || categoriaState.pratos.length === 0;

	return (
		<div className="border bg-white p-4 mt-3 rounded-md">
			<div className="border-b pb-3 flex justify-between items-center">
				<div className="flex flex-col">

					<div className="flex items-center">
						<Switch
							checked={categoria.ativo}
							onChange={() => handleToggleCategoria(categoria)}
							inputProps={{ "aria-label": "controlled" }}
						/>
						<label className="ml-2">Ativar Categoria</label>
					</div>
					<div className="flex items-center">
						<span className="mr-3 font-bold">{categoria.nome}</span>
						<button
							className="flex items-center border border-red-600 hover:bg-gray-100 rounded-md p-2 mt-2"
							onClick={handleAbrirModal}
						>
							<FiPlus className="text-red-500" />
							<span className="text-red-500">Adicionar Item</span>
						</button>
					</div>

				</div>

				<div className="flex justify-center items-center gap-2">

					<button
						onClick={toggleExpand}
						disabled={isLoading}
						className="flex items-center text-gray-600"
					>
						{isLoading ? (
							<AiOutlineLoading className="mr-2 text-3xl animate-spin" />
						) : isExpanded ? (
							<HiChevronDoubleUp className="mr-2 text-3xl" />
						) : (
							<HiChevronDoubleDown className="mr-2 text-3xl" />
						)}
					</button>

					{/* {isPratosEmpty && (
						<button>
							<BsTrash
								size={20}
								onClick={abrirModalExcluirCategoria}
							/>
						</button>
					)} */}
				</div>
			</div>

			<div
				className={`flex flex-col  transition-all duration-300 ${isExpanded ? "h-full" : "max-h-0 overflow-hidden"}`}
			>
				{pratosDaCategoria?.map((prato, index) => (
					<Item
						key={index}
						prato={prato}
						onExcluir={handleExcluirPrato}
						listaAdicionais={listaAdicionais}
						idRestaurante={localStorage.getItem('restaurante')}
					/>
				))}
			</div>

			<Dialog
				open={modalAberto}
				onClose={(event, reason) => {
					if (reason !== 'backdropClick') {
						handleFecharModal();
					}
				}}
				maxWidth="md"
				fullWidth
			>
				<DialogTitle>Novo Produto</DialogTitle>

				<DialogContent dividers>
					<div className="flex gap-4">
						<div className="relative flex items-center justify-center w-[240px] h-[200px] flex-shrink-0">
							{novoItem.prato.foto ? (
								<img
									src={URL.createObjectURL(novoItem.prato.foto)}
									alt="Imagem selecionada"
									className="w-auto h-full object-cover rounded-md"
								/>
							) : (
								<div className="flex p-6 items-center justify-center w-full h-full border-2 border-dashed rounded-md bg-gray-100">
									<span className="text-gray-500 text-center">Clique aqui para selecionar uma imagem</span>
								</div>
							)}
							<input
								type="file"
								onChange={(e) => handleChangeNovoItem("foto", e.target.files[0])}
								className="absolute inset-0 opacity-0 cursor-pointer"
							/>
						</div>

						<div className="flex flex-col justify-between flex-grow gap-4">
							<TextField
								label="Nome"
								value={novoItem.prato.nome}
								onChange={(e) => handleChangeNovoItem("nome", e.target.value)}
								fullWidth
							/>
							<TextField
								label="Descrição"
								value={novoItem.prato.descricao}
								onChange={(e) => handleChangeNovoItem("descricao", e.target.value)}
								fullWidth
							/>
							<CurrencyInput
								options={{
									precision: 2,
									style: "currency",
									locale: "pt-BR",
									i18nCurrency: "BRL",
								}}
								placeholder="Preço"
								value={novoItem.prato.preco}
								onChangeEvent={handleChangePreco}
								className="w-full px-3 py-2 border rounded-lg"
							/>
						</div>
					</div>

					<div className="mt-4">
						<Button
							variant="contained"
							color="primary"
							onClick={adicionarCategoria}
						>
							+ Grupo adicional
						</Button>
						{categoriasAdicionais.map((_, index) => (
							<CategoriaAdicional
								key={index}
								index={index}
								categoriasAdicionais={categoriasAdicionais}
								setCategoriasAdicionais={setCategoriasAdicionais}
								listaAdicionais={listaAdicionais}
							/>
						))}
					</div>
				</DialogContent>

				<DialogActions>
					<Button onClick={handleFecharModal} color="primary">
						Fechar
					</Button>
					<Button onClick={handleSalvarNovoItem} variant="contained" color="primary">
						Salvar
					</Button>
				</DialogActions>
			</Dialog>

			{modalAbertoExcluirCategoria && (
				<div className="modal fixed inset-0 bg-gray-600 bg-opacity-50 flex items-center justify-center">
					<div className="bg-white p-4 rounded shadow-lg">
						<h2 className="text-lg font-semibold mb-4">
							Confirmar Exclusão
						</h2>
						<p>Tem certeza que deseja excluir este item?</p>
						<div className="mt-4 flex justify-end">
							<button
								className="px-4 py-2 mr-2 bg-gray-300 rounded"
								onClick={fecharModalExcluirCategoria}
							>
								Cancelar
							</button>
							<button
								className="px-4 py-2 bg-red-600 text-white rounded"
								onClick={handleExcluirCategoria}
							>
								Excluir
							</button>
						</div>
					</div>
				</div>
			)}
		</div>
	);
}
